<template lang="pug">
div(:class='[alignmentClasses, { relative: $storyblok.isEditing.value }]')
  AlgoliaQueryInput(
    v-if='displayType'
    type='text'
    :placeholder='data.placeholder'
    :update-route='false'
    class='border border-gray-light rounded color-dark bg-white bg-no-repeat w-full text-sm p-3 md:max-w-screen-sm md:h-16 md:p-4 md:m-auto md:text-base'
  )
  //- Render an error box if this widget is being used outside of the Algolia Wrapper
  div(v-else-if='$storyblok.isEditing.value' class='p-2 mb-4 text-sm text-center bg-danger-light border border-danger rounded') Error: The Search Input Widget may only be used inside of a Search Content Wrapper.

  //- Shade for storyblok editor to prevent clicks
  div(v-if='$storyblok.isEditing.value' class='absolute inset-0 z-50')
</template>

<script setup lang="ts">
interface InputSearch extends Widget, WidgetAlignment {
  placeholder: string
}

// If this is not Provided than the widgets is being used outside of the Algolia Wrapper
const displayType = inject('displayType')

const { data } = defineProps<{
  data: InputSearch
}>()

const alignmentClasses = computed(() => {
  return generateTextAlignmentClasses(data.mobileAlignment, data.tabletAlignment, data.desktopAlignment)
})
</script>
